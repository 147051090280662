import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 143px;
  width: 100%;

  @media only screen and (max-width: 575.98px) {
    margin-top: 60px !important;
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    margin-top: 60px;
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 1200px) {
    margin-top: 143px;
  }
`

export const SectionHeader = styled.div`
  background: linear-gradient(180deg, #202020 8.01%, #23526f 126.28%);
  > div {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      margin-left: 80px;

      > button {
        background: none;
        margin-top: 135px;
        width: 48px;
        height: 48px;

        > img {
          width: 48px;
          height: 48px;
        }
      }
    }

    > img {
      width: 786px;
      height: 100%;
      transform: scaleX(-1);
    }
  }

  @media (max-width: 576.98px) {
    padding: 12px 16px 50px 12px;
    align-items: center;
    min-height: 420px !important;
    max-height: 600px !important;

    > div {
      min-height: 420px !important;
      max-height: 600px !important;
      flex-direction: column;
      align-items: center;
      padding: 0 !important;
      position: relative;

      > div {
        margin: 0;
        z-index: 1;
        position: absolute;
        top: 50px;
        left: 0;

        > button {
          display: none;
        }
      }

      > img {
        opacity: 0.2;
        max-width: 575.98px;
        height: 420px !important;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    padding: 12px 0 50px 12px;
    align-items: center;
    min-height: 420px !important;
    max-height: 600px !important;
    justify-content: space-between;
    > div {
      min-height: 420px !important;
      max-height: 600px !important;
      flex-direction: column;
      align-items: center;
      padding: 0 !important;
      position: relative;

      > div {
        margin: 0;
        z-index: 1;
        position: absolute;
        top: 50px;
        left: 0;

        > button {
          display: none;
        }
      }

      > img {
        opacity: 0.2;
        max-width: 575.98px;
        height: 420px !important;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding: 12px 0 50px 12px;
    align-items: center;
    min-height: 420px !important;
    max-height: 600px !important;
    justify-content: space-between;
    > div {
      height: 600px;
      flex-direction: column;
      align-items: center;
      padding: 0 !important;
      position: relative;

      > div {
        margin: 0;
        z-index: 1;
        position: absolute;
        top: 50px;
        left: 0;

        > button {
          display: none;
        }
      }

      > img {
        opacity: 0.2;
        max-width: 575.98px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`

export const Title = styled.div`
  width: 552px;
  font-style: normal;
  font-weight: normal;
  font-size: 48px !important;
  line-height: 67px;
  color: ${props => props.theme.colors.white};

  @media only screen and (max-width: 575.98px) {
    width: auto;
    text-align: center;
    font-size: 32px !important;
    line-height: 48px;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    width: auto;
    font-size: 32px !important;
    line-height: 48px;
  }
`

export const Subtitle = styled.div`
  color: ${props => props.theme.colors.white};
  max-width: 542px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  margin-top: 20px;

  @media only screen and (max-width: 575.98px) {
    font-size: 18px;
    text-align: center;
    width: auto;
    height: auto !important;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    font-size: 18px;
    width: auto;
    height: auto !important;
  }
`

export const SectionContent = styled.div`
  margin: 80px;
`

export const ContainerFlex = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  > div {
    color: ${props => props.theme.colors.tertiary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 40px 80px;

    > div {
      width: 800px;
      > div:nth-child(1) {
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 40px;
      }

      > div:nth-child(2) {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 80px;
      }
    }

    > img {
      width: auto;
      height: 453px;
    }
  }

  @media only screen and (max-width: 575.98px) {
    > div {
      flex-direction: column;
      margin: 16px 32px;
      > div {
        width: 100%;
        > div:nth-child(1) {
          width: 100%;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        > div:nth-child(2) {
          width: 100%;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 40px;
        }
      }

      > img {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      flex-direction: column;
      margin: 16px 32px;
      > div {
        > div:nth-child(1) {
          width: 100%;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        > div:nth-child(2) {
          width: 100%;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 40px;
        }
      }
    }
  }
`

export const ContainerValues = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  background-color: #f8f8f8;
  > div {
    width: 100%;
    color: ${props => props.theme.colors.tertiary};
    display: flex;
    justify-content: space-between;
    padding: 120px 80px;

    > div {
      width: 305px;
      > div {
        display: flex;
        align-items: center;

        > img {
          width: 48px;
          height: 48px;
        }

        > h3 {
          margin-left: 20px;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 45px;
        }
      }

      > p {
        width: 291px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (max-width: 575.98px) {
    > div {
      flex-direction: column;
      align-items: center;
      padding: 16px 32px;

      > div {
        align-items: center;
        margin-bottom: 20px;
      }

      > p {
        text-align: center;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      flex-wrap: wrap;
      align-items: center;
      padding: 16px 32px;

      > div {
        align-items: center;
        margin-bottom: 20px;
      }

      > p {
        text-align: center;
      }
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    > div {
      flex-wrap: wrap;
      align-items: center;
      padding: 16px 32px;

      > div {
        align-items: center;
        margin-bottom: 20px;
      }

      > p {
        text-align: center;
      }
    }
  }
`

export const SectionFooter = styled.div`
  background: linear-gradient(180deg, #202020 8.01%, #23526f 126.28%);
  padding-top: 65px;
  padding-bottom: 65px;

  > p {
    color: ${props => props.theme.colors.white};
    text-align: center;
  }
`

export const Button = styled.a`
  color: ${props => props.theme.colors.orange};
  border: 1px solid ${props => props.theme.colors.orange};
  text-decoration: none;
  display: inline-block;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
`

export const PrimaryBtn = styled.a`
  padding: 12px 16px;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`

export const SeccondaryBtn = styled.a`
  padding: 12px 16px;
  border: 1px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
`

export const BtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 320px;

  @media only screen and (max-width: 575.98px) {
    width: 100% !important;
  }
`

export const Pesquisar = styled.a`
  max-height: 47px;
  display: inline-block;
  color: ${props => props.theme.colors.white};
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  background: ${props => props.theme.colors.secondary};
  text-decoration: none;
`
