import React, { useEffect, useState } from 'react'
import GlobalStyles from './style/GlobalStyles'
import {
  BrowserRouter,
  Route,
  Routes,
  Location,
  useLocation
} from 'react-router-dom'
import { api } from './api'
import { useDispatch, useSelector } from 'react-redux'
import {
  SET_TOKEN,
  GET_USER,
  GET_ABOUT,
  GET_ABOUT_API,
  GET_HOME,
  GET_NEWS,
  GET_POSTS_NEWS,
  GET_SOCIAL_NETWORK,
  GET_TRANSPARENCIES,
  GET_REPORTS,
  GET_IMAGE_SOCIAL_MEDIA,
  GET_STATISTICS
} from './store/actions'
import {
  getAbout,
  getAboutApi,
  getHome,
  getNews,
  getSocialNetwork,
  getTransparencies,
  getReports,
  getImageSocialMedia,
  getStatistics
} from './functions'
import { RootState } from './store'

import Header from './components/Header'
import AuthRoutes from './routes'
import Footer from './components/Footer'
import ScrollToTop from './functions/scrollToTop'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const {
    token,
    user,
    home,
    posts,
    about,
    about_api,
    transparencies,
    social_networks,
    posts_news,
    image_sm,
    reports
  } = useSelector((state: RootState) => state.clickState)
  const [verification, setVerification] = useState(true)

  useEffect(() => {
    let obj = {
      email: process.env.REACT_APP_API_AUTH_EMAIL,
      password: process.env.REACT_APP_API_AUTH_PASSWORD
    }
    api
      .post('/authorize', obj)
      .then(resp => {
        dispatch({ type: GET_USER, user: resp.data.user })
        dispatch({ type: SET_TOKEN, token: resp.data.token })

        getHome(resp.data.token).then(resp => {
          dispatch({ type: GET_HOME, home: resp })
        })
        getAbout(resp.data.token).then(resp => {
          dispatch({ type: GET_ABOUT, about: resp })
        })
        getAboutApi(resp.data.token).then(resp => {
          dispatch({ type: GET_ABOUT_API, about_api: resp })
        })
        getNews(resp.data.token).then(resp => {
          dispatch({ type: GET_NEWS, posts: resp.data })
        })
        getSocialNetwork(resp.data.token).then(resp => {
          dispatch({ type: GET_SOCIAL_NETWORK, social_networks: resp })
        })
        getImageSocialMedia(resp.data.token).then(resp => {
          dispatch({ type: GET_IMAGE_SOCIAL_MEDIA, image_sm: resp })
        })
        getTransparencies(resp.data.token).then(resp => {
          dispatch({ type: GET_TRANSPARENCIES, transparencies: resp })
        })
        getNews(resp.data.token).then(resp => {
          dispatch({ type: GET_POSTS_NEWS, posts_news: resp.data })
        })
        getReports(resp.data.token).then(resp => {
          dispatch({ type: GET_REPORTS, reports: resp.data })
        })
        getStatistics(resp.data.token).then(resp => {
          dispatch({ type: GET_STATISTICS, statistics: resp })
        })
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var iframe: any = document.querySelector('body > iframe')
    if (!!iframe) {
      iframe.parentNode.removeChild(iframe)
    }
  }, [])

  {
    /* <Routes>
                <Route path="/politica/politica-de-privacidade" element={ < PoliticaDePrivacidadeApp/> }/>
              </Routes> */
  }
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Header />
          <AuthRoutes />
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default App
