import React, { useEffect } from 'react'
import {
  Container,
  SectionHeader,
  Title,
  ContainerFlex,
  ContainerValues,
  SectionFooter,
  Subtitle,
  Button,
  PrimaryBtn,
  SeccondaryBtn,
  BtnDiv,
  Pesquisar
} from '../Api/style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getAboutApi } from '../../functions'
import { GET_ABOUT_API } from '../../store/actions'
import renderHTML from 'react-render-html'
import DefaultBtn from '../../components/DefaultButton/index'
import SecondBtn from '../../components/SecondBtn'
import Helmet from 'react-helmet'
import downSection from '../../assets/circleDown.png'

const AboutApi: React.FC = () => {
  const dispatch = useDispatch()
  const { token, about_api } = useSelector(
    (state: RootState) => state.clickState
  )

  useEffect(() => {
    getAboutApi(token).then(resp => {
      dispatch({ type: GET_ABOUT_API, about_api: resp })
    })
  }, [])

  return (
    <Container>
      <Helmet title="API - Fogo Cruzado" />
      {about_api?.map((id: any) => {
        if (id?.id == '6484ce52-1824-4e34-ae57-0b43129b9ff0') {
          return (
            <SectionHeader>
              <div>
                <div>
                  <Title>
                    {id?.title.length > 0 ? renderHTML(id?.title) : ''}
                  </Title>
                  <Subtitle>
                    {id?.description.length > 0
                      ? renderHTML(id?.description)
                      : ''}
                  </Subtitle>
                  <button onClick={() => window.scrollTo(0, 700)}>
                    <img src={downSection} />
                  </button>
                </div>
                <img src={id?.image?.file} alt="Connection Tools" />
              </div>
            </SectionHeader>
          )
        }
        if (id?.id == 'b4089963-0d1b-40b6-b527-b8848e6f8635') {
          return (
            <ContainerFlex>
              <div>
                <img src={id?.image?.file} alt="tools" />
                <div>
                  <div>{id?.title.length > 0 ? renderHTML(id?.title) : ''}</div>
                  <div>
                    {id?.description.length > 0
                      ? renderHTML(id?.description)
                      : ''}
                  </div>
                  <BtnDiv>
                    <PrimaryBtn
                      href="https://api.fogocruzado.org.br/docs/1.0/overview"
                      target="_blank"
                    >
                      Ler documentação
                    </PrimaryBtn>
                    <SeccondaryBtn
                      href="https://api.fogocruzado.org.br/#authorization"
                      target="_blank"
                    >
                      Acessar API
                    </SeccondaryBtn>
                  </BtnDiv>
                </div>
              </div>
            </ContainerFlex>
          )
        }
        if (id?.id == 'e657ebd5-8ce0-4ff2-9527-1c95f437a2d3') {
          return (
            <ContainerValues>
              <div>
                {id?.items?.map((item: any) => {
                  return (
                    <div>
                      <div>
                        <img src={item?.image?.file} alt="img item" />
                        <h3>
                          {item?.title.length > 0
                            ? renderHTML(item?.title)
                            : ''}
                        </h3>
                      </div>
                      <p>
                        {item?.description.length > 0
                          ? renderHTML(item?.description)
                          : ''}
                      </p>
                    </div>
                  )
                })}
              </div>
            </ContainerValues>
          )
        }
        if (id?.id == 'eaba1e00-7ab1-4f4a-9326-b1bb261b2e08') {
          return (
            <ContainerFlex>
              <div>
                <img src={id?.image?.file} alt="pana" />
                <div>
                  <div>{id?.title.length > 0 ? renderHTML(id?.title) : ''}</div>
                  <div>
                    {id?.description.length > 0
                      ? renderHTML(id?.description)
                      : ''}
                  </div>
                  <DefaultBtn to="" text="Solicitar autorização" />
                </div>
              </div>
            </ContainerFlex>
          )
        }
        if (id?.id == 'd5985ca6-06da-4083-ab9c-b0e1581055c7') {
          return (
            <>
              <ContainerFlex>
                <div>
                  <div>
                    <div>
                      {id?.title.length > 0 ? renderHTML(id?.title) : ''}
                    </div>
                    <div>
                      {id?.description.length > 0
                        ? renderHTML(id?.description)
                        : ''}
                    </div>
                    <BtnDiv>
                      <Pesquisar
                        href="https://api.fogocruzado.org.br/ocurrences"
                        target="_blank"
                      >
                        Pesquisar
                      </Pesquisar>
                      <Button
                        href="https://github.com/voltdatalab/crossfire"
                        target="_blank"
                      >
                        Pacote R (CrossFire)
                      </Button>
                    </BtnDiv>
                  </div>
                  <img src={id?.image?.file} alt="bro" />
                </div>
              </ContainerFlex>
              <SectionFooter>
                <p>
                  Desenvolvida pela agência <strong>Volt Data Lab</strong>..
                </p>
              </SectionFooter>
            </>
          )
        }
      })}
    </Container>
  )
}

export default AboutApi
