import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { convertData, getAllNewsByParams, getReports } from '../../functions'
import renderHTML from 'react-render-html'

import NewsList from '../../components/NewsList'
import DefaultButton from '../../components/DefaultButton'
import DownloadApp from '../../components/DownloadApp'
import OurData from '../../components/OurData'
import CardPost from '../../components/CardPost'

import downSection from '../../assets/circleDown.png'
import mockupFcHome from '../../assets/mockup-fc-home.svg'
import smartPhoneResp from '../../assets/smartPhoneResp.svg'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'

import {
  Container,
  SectionHeader,
  SectionHeaderLeft,
  SectionHeaderTitle,
  SectionOurData,
  TitleData,
  ContainerOurData,
  SmarthPhone,
  SmartPhoneResp,
  SectionNewsBlog,
  SectionAPI,
  Supporter,
  News,
  SectionDownloadApp,
  TitleApi,
  SubtitleApi,
  Loading
} from '../Home/style'

const Home: React.FC = () => {
  const { home, token, statistics } = useSelector(
    (state: RootState) => state.clickState
  )
  const [header, setHeader] = useState<any>()
  const [aboutApi, setAboutApi] = useState<any>()
  const [footer, setFooter] = useState<any>()
  const [post, setPost] = useState<any>()

  const [isLoading, setIsLoading] = useState(false)
  const [hasReportsInHeader, setHasReportsInHeader] = useState(false)
  const [postForHeader, setPostForHeader] = useState<any>([])

  async function getPostsForHeader() {
    setIsLoading(true)
    let auxPostForHeader: any[] = []

    await getReports(token, 'DESC').then(resp => {
      const reportsEmpasis = resp.data.find(
        (item: { emphasis: boolean }) => item.emphasis
      )
      if (reportsEmpasis) {
        setHasReportsInHeader(true)
        auxPostForHeader.push(reportsEmpasis)
      }
    })

    await getAllNewsByParams(token, 'DESC').then(resp => {
      resp.data.forEach((item: { emphasis: boolean }) => {
        if (item.emphasis && auxPostForHeader.length < 4) {
          auxPostForHeader.push(item)
        }
      })
    })

    setPostForHeader(auxPostForHeader)
    setIsLoading(false)
  }

  function getNews() {
    getAllNewsByParams(token, 'DESC', '1', '4').then(resp => {
      setPost(resp.data)
    })
  }

  function getHeader() {
    home.forEach(id => {
      if (id.id == 'b1158917-0eae-4ff5-a283-3d058eae7608') {
        setHeader(id)
      } else if (id.id == '9c81fcfb-d2a3-4717-b7a8-3f3772935db6') {
        setAboutApi(id)
      } else if (id.id == '0a4c0b7f-811f-4f3c-9c51-693ffae10b11') {
        setFooter(id)
      }
    })
  }

  useEffect(() => {
    getHeader()
  }, [home])

  useEffect(() => {
    getPostsForHeader()
    getNews()
  }, [])

  return (
    <Container>
      <Helmet title="Fogo Cruzado" />
      <SectionHeader>
        <div>
          <SectionHeaderLeft image={header?.image?.file}>
            <SectionHeaderTitle>
              <div>
                {header?.title.length > 0 ? renderHTML(header?.title) : ''}
              </div>
              <div>
                {header?.description.length > 0
                  ? renderHTML(header?.description)
                  : ''}
              </div>
              <button onClick={() => window.scrollTo(0, 800)}>
                <img src={downSection} />
              </button>
            </SectionHeaderTitle>
          </SectionHeaderLeft>
          <News>
            {isLoading ? (
              <Loading>
                <CircularProgress color="inherit" />
              </Loading>
            ) : (
              postForHeader.map((item: any, index: number) => (
                <NewsList
                  key={index}
                  img={item?.cover?.file}
                  to={
                    hasReportsInHeader && index === 0
                      ? `/dados/relatorios/${item?.slug}`
                      : `/${item?.slug}`
                  }
                  local={
                    item?.region?.state?.length > 0
                      ? renderHTML(item?.region?.state)
                      : ''
                  }
                  title={item?.title?.length > 0 ? renderHTML(item?.title) : ''}
                  content={
                    item?.description?.length > 0
                      ? renderHTML(item?.description)
                      : ''
                  }
                />
              ))
            )}
          </News>
        </div>
      </SectionHeader>

      <SectionOurData>
        <div>
          <TitleData>Nossos dados</TitleData>
          <ContainerOurData id="statistics">
            <OurData
              number={statistics?.total_occurrences}
              text="Tiroteios/disparos de armas de fogo registrados"
            />
            <OurData
              number={statistics?.total_police_operation}
              text="Tiros em ações/operações policiais registrados"
            />
            <OurData
              number={statistics?.total_deads}
              text="Mortos registrados"
            />
            <OurData
              number={statistics?.total_wounded}
              text="Feridos registrados"
            />
          </ContainerOurData>

          <SmarthPhone src={mockupFcHome} />
          <SmartPhoneResp>
            <img src={smartPhoneResp} />
          </SmartPhoneResp>
        </div>
      </SectionOurData>
      <SectionDownloadApp>
        <DownloadApp />
      </SectionDownloadApp>

      <SectionNewsBlog>
        <div>
          <h3>Últimas notícias</h3>
          <section>
            {post?.map((id: any) => {
              return (
                <CardPost
                  img={id?.cover?.file}
                  to={`/${id?.slug}`}
                  local={
                    id?.region?.state?.length > 0
                      ? renderHTML(id?.region?.state)
                      : ''
                  }
                  title={id?.title?.length > 0 ? renderHTML(id?.title) : ''}
                  author={
                    id?.author?.name?.length > 0 ? renderHTML(id?.authors) : ''
                  }
                  date={convertData(id?.createdAt)}
                />
              )
            })}
          </section>
          <div>
            <DefaultButton to="/Noticias" text="Quero ver mais" />
          </div>
        </div>
      </SectionNewsBlog>

      <SectionAPI>
        <div>
          <div>
            <TitleApi>
              {aboutApi?.title.length > 0 ? renderHTML(aboutApi?.title) : ''}
            </TitleApi>
            <SubtitleApi>
              {aboutApi?.description.length > 0
                ? renderHTML(aboutApi?.description)
                : ''}
            </SubtitleApi>
            <div>
              <DefaultButton to="/api" text="Quero conhecer mais sobre a API" />
            </div>
          </div>
          <img src={aboutApi?.image?.file} />
        </div>
      </SectionAPI>
      <Supporter>
        <div>
          <div>
            <TitleApi>
              {footer?.title.length > 0 ? renderHTML(footer?.title) : ''}
            </TitleApi>
            <SubtitleApi>
              {footer?.description.length > 0
                ? renderHTML(footer?.description)
                : ''}
            </SubtitleApi>
            <div>
              {/* <DefaultButton to="/seja-um-apoiador" text="Quero apoiar"/> */}
            </div>
          </div>
          <img src={footer?.image?.file} />
        </div>
        <br />
      </Supporter>
    </Container>
  )
}

export default Home
